<template>
  <b-row>
    <b-col cols="12">
      <div class="card card-box">
        <ul class="list-group list-group-flush">
          <li
            v-for="videoLesson in videoLessons"
            :key="`lession__video-${videoLesson.id}`"
            class="list-group-item pt-4 pb-4"
          >
            <div class="d-flex flex-column flex-sm-row align-items-start">
              <div>
                <div class="card card-transparent mb-3 mb-sm-0">
                  <a
                    target="_blank"
                    :href="videoLesson.lesson_url"
                    class="card-img-wrapper rounded"
                    style="width: 160px"
                  >
                    <div class="img-wrapper-overlay">
                      <div class="overlay-btn-wrapper">
                        <b-button
                          size="sm"
                          pill
                          variant="outline-secondary"
                          class="
                            border-0
                            shadow-none
                            pl-2
                            pr-2
                            mx-auto
                            d-flex
                            align-items-center
                          "
                        >
                          <font-awesome-icon
                            icon="play-circle"
                            class="font-size-lg"
                          />
                        </b-button>
                      </div>
                    </div>

                    <b-embed type="video">
                      <source
                        :src="`${videoLesson.lesson_url}#t=2`"
                        type="video/mp4"
                      />
                    </b-embed>
                  </a>
                </div>
              </div>

              <div class="pl-0 pl-sm-4">
                <div class="mb-1 font-size-lg">
                  บทเรียนที่ {{ videoLesson.lesson_no }}
                </div>

                <p class="mb-0 mt-2 text-black-50">
                  {{ videoLesson.lesson_title }}
                </p>
              </div>

              <div class="mt-3 mt-sm-0 ml-sm-auto">
                <b-button
                  variant="primary"
                  target="_blank"
                  :href="videoLesson.lesson_url"
                  class="mr-2"
                >
                  <span class="btn-wrapper--icon">
                    <font-awesome-icon icon="play-circle" class="mr-2" />

                    <span>ดูบทเรียน</span>
                  </span>
                </b-button>

                <b-button
                  variant="danger"
                  @click.prevent="download(videoLesson)"
                >
                  <b-spinner
                    v-if="isDownloading && isDownloadingId === videoLesson.id"
                    label="ดาวน์โหลด..."
                  ></b-spinner>

                  <span v-else class="btn-wrapper--icon">
                    <font-awesome-icon icon="file-download" class="mr-2" />

                    <span>ดาวน์โหลด</span>
                  </span>
                </b-button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { Lesson } from "../../models";

export default {
  data() {
    return {
      isDownloading: false,
      isDownloadingId: null,
    };
  },

  computed: {
    videoLessons() {
      return Lesson.query().where("lesson_type", "video").get();
    },
  },

  methods: {
    async download(lesson) {
      this.isDownloading = true;
      this.isDownloadingId = lesson.id;

      try {
        const url = await lesson.getDownloadUrl();

        const a = document.createElement("a");

        a.href = url;

        a.click();

        a.remove();
      } catch (error) {
        console.log(error);
        this.$toast.error("ไม่สามารถดาวน์โหลดไฟล์ได้ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isDownloading = false;
        this.isDownloadingId = null;
      }
    },
  },
};
</script>
https://dare-user-upload.s3.ap-southeast-1.amazonaws.com/lessons/Dare_EP1_%E0%B9%81%E0%B8%99%E0%B8%B0%E0%B8%99%E0%B8%B3%E0%B9%82%E0%B8%84%E0%B8%A3%E0%B8%87%E0%B8%81%E0%B8%B2%E0%B8%A3%2BD.A.R.E..mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXTK2WH33QRV3RAKF%2F20211121%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20211121T080352Z&X-Amz-Expires=216000&X-Amz-Signature=a95ade579127c26b29f69abb490741c6c47e1a2f5ad5bc889a2ac12f7d7c07ea&X-Amz-SignedHeaders=host&response-content-disposition=attachment
